import React from 'react';
import { AQLEnabled } from '../shared/util/AQLEnabled';
import { cuttingEnabled } from '../shared/util/cuttingEnabled';
import { idleTimeEnabled } from '../shared/util/idleTimeEnabled';
import AccessDenied from './AccessDenied';
import ReloadPage from './ReloadPage';

const DefectsGroup = React.lazy(
    () => import('../Quality/Defects/DefectsGroup'),
);

const SingleInspectionDetail = React.lazy(
    () => import('../Quality/SingleInspectionDetail/SingleInspectionDetail'),
);
const Overhead = React.lazy(
    () => import('app/modules/BasicInfo/Overhead/Overhead'),
);

const AggregatedCostingReport = React.lazy(
    () =>
        import(
            'app/modules/BasicInfo/AggregatedCostingReport/AggregatedCostingReport'
        ),
);

const IdleTimeReasons = React.lazy(
    () => import('app/modules/BasicInfo/idleTime/IdleTimeReasons'),
);

const StyleCategoryAnalysis = React.lazy(
    () =>
        import(
            '../AnalyticsAndReports/ProcessTimeAnalysisReport/ProcessTimeAnalysisReport'
        ),
);
const ActionsLogs = React.lazy(() => import('../ActionsLogs'));

const Admin = React.lazy(() => import('app/modules/Admin'));

const StyleCategory = React.lazy(
    () => import('app/modules/Production/StyleCategory/StyleCategory'),
);

const StyleCategoryForm = React.lazy(
    () =>
        import(
            'app/modules/Production/StyleCategory/components/StyleCategoryForm'
        ),
);

const StyleCategories = React.lazy(
    () => import('../Production/StyleCategory/StyleCategories'),
);

const SectionTags = React.lazy(
    () => import('../Production/SectionTags/SectionTags'),
);

const ProductionLines = React.lazy(
    () => import('../Production/Lines/ProductionLines'),
);

const ProcessCombinations = React.lazy(
    () => import('../Production/ProcessCombination/ProcessCombinations'),
);

const ProcessCombination = React.lazy(
    () => import('../Production/ProcessCombination/ProcessCombination'),
);

const Styles = React.lazy(() => import('../Production/Style/Styles'));
const ProcessesMap = React.lazy(
    () => import('../Production/Style/ProcessesMap/ProcessesMap'),
);
const StylePrint = React.lazy(
    () => import('../Production/Style/StylePrint/StylePrint'),
);

const ProductionOrders = React.lazy(
    () => import('../Production/Order/ProductionOrders/ProductionOrders'),
);

const ProductionPlan = React.lazy(
    () => import('../Planning/ProductionPlan/ProductionPlan'),
);

const DeletedProductionOrders = React.lazy(
    () => import('../Production/Order/ProductionOrders/Deleted/index'),
);

const AddEditProductionOrder = React.lazy(
    () =>
        import(
            '../Production/Order/AddEditProductionOrder/AddEditProductionOrder'
        ),
);

const CuttingOrders = React.lazy(
    () => import('../Cutting/CuttingOrders/CuttingOrders'),
);

const AddEditCuttingOrder = React.lazy(
    () => import('../Cutting/AddEditCuttingOrder/AddEditCuttingOrder'),
);

const CuttingOrderDetails = React.lazy(
    () => import('../Cutting/CuttingOrderDetails/CuttingOrderDetails'),
);

const BatchHistory = React.lazy(
    () => import('../Production/Order/BatchHistory'),
);

const BatchHistoryV2 = React.lazy(
    () => import('../Production/Order/BatchHistory/BatchHistoryV2'),
);

const EmployeesEfficiency = React.lazy(
    () =>
        import(
            '../AnalyticsAndReports/EmployeesEfficiencyReport/EmployeesEfficiencyReport'
        ),
);

const EmployeeHistory = React.lazy(
    () =>
        import(
            '../AnalyticsAndReports/EmployeesEfficiencyReport/EmployeeHistory/EmployeeHistory'
        ),
);

const LinesEfficiencyReport = React.lazy(
    () =>
        import(
            '../AnalyticsAndReports/LinesEfficiencyReport/LinesEfficiencyReport'
        ),
);

const LinesPerformanceReport = React.lazy(
    () =>
        import(
            '../AnalyticsAndReports/LinesPerformanceReport/LinesPerformanceReport'
        ),
);
const Dashboard = React.lazy(
    () => import('../AnalyticsAndReports/Dashboard/Dashboard'),
);

const HourlyReport = React.lazy(
    () => import('../AnalyticsAndReports/HourlyReport/HourlyReport'),
);

const LandPage = React.lazy(() => import('./LandPage'));

const Displays = React.lazy(() => import('../Display/ManageDisplays/Displays'));

const StationsManager = React.lazy(
    () => import('app/modules/Stations/StationsManager/StationsManager'),
);

const Stations = React.lazy(() => import('app/modules/Stations/Stations'));
const Station = React.lazy(() => import('app/modules/Stations/Station'));

const ControlStationThroughput = React.lazy(
    () =>
        import(
            'app/modules/Stations/ControlStationThroughput/ControlStationThroughput'
        ),
);

const QualityStationThroughput = React.lazy(
    () =>
        import(
            'app/modules/Stations/QualityStationThroughput/QualityStationThroughput'
        ),
);

const LineFeedingReport = React.lazy(
    () => import('../AnalyticsAndReports/LineFeedingReport/LineFeedingReport'),
);

const TransLations = React.lazy(
    () => import('app/modules/CompanyManagement/Translations/Translation'),
);

const CompanyRoles = React.lazy(
    () => import('app/modules/CompanyManagement/CompanyRoles/CompanyRoles'),
);

const AddEditRole = React.lazy(
    () =>
        import(
            'app/modules/shared/components/RolesComponents/AddEditRole/AddEditRole'
        ),
);

const ProductionOrderCostingReport = React.lazy(
    () =>
        import(
            '../AnalyticsAndReports/ProductionOrderCostingReport/ProductionOrderCostingReport'
        ),
);

const ProductionOrderPlanning = React.lazy(
    () => import('../Production/Order/Planning/Planning'),
);

const MaintenanceReport = React.lazy(
    () => import('../Maintainance/MaintenanceReport/MaintenanceReport'),
);
const MaintenanceSupervisorReport = React.lazy(
    () =>
        import(
            '../Maintainance/MaintenanceSupervisor/MaintenanceSupervisorReport'
        ),
);

const ManualWorkingHours = React.lazy(
    () => import('../BasicInfo/ManualWorkingHours/ManualWorkingHours'),
);

const NewManualWorkingHours = React.lazy(
    () => import('../BasicInfo/ManualWorkingHoursV2/ManualWorkingHours'),
);

const MaintenanceRequests = React.lazy(
    () => import('app/modules/Maintainance/Requests/Requests'),
);

const Supervisor = React.lazy(() => import('../Supervisor/Supervisor'));
const IdleTimes = React.lazy(
    () => import('../Supervisor/IdleTime/IdleTimes/IdleTimes'),
);
const IdleTimeEntry = React.lazy(
    () => import('../Supervisor/IdleTime/IdleTimeEntry/IdleTimeEntry'),
);
const IdleTimeReasonsAnalysis = React.lazy(
    () =>
        import('../Supervisor/IdleTimeReasonsAnalysis/IdleTimeReasonsAnalysis'),
);
const MaintenanceDowntimeReasons = React.lazy(
    () => import('../Maintainance/DowntimeReasons/DowntimeReasons'),
);

const InlineMachineDefects = React.lazy(
    () =>
        import(
            '../Maintainance/InlineMachineDefects/InlineMachineDefectsReport'
        ),
);

const NotificationsSettings = React.lazy(
    () => import('../Settings/NotificationsSettings/NotificationsSettings'),
);

const Singin = React.lazy(() => import('../SignIn/SignIn'));
const ChangePassword = React.lazy(() => import('../User/ChangePassword'));
const ErrorPage = React.lazy(() => import('components/Error404'));
const Factories = React.lazy(
    () => import('app/modules/CompanyManagement/Factories/Factories'),
);
const Employees = React.lazy(
    () => import('app/modules/BasicInfo/Employees/Employees'),
);
const EmployeeScoreCalculator = React.lazy(
    () =>
        import(
            'app/modules/BasicInfo/EmployeeScoreCalculator/EmployeeScoreCalculator'
        ),
);
const FactoryScorecardReport = React.lazy(
    () =>
        import(
            'app/modules/BasicInfo/FactoryScorecardReport/FactoryScorecardReport'
        ),
);
const EmployeeScoreCard = React.lazy(
    () => import('app/modules/BasicInfo/EmployeeScoreCard/EmployeeScoreCard'),
);

const UnplannedStoppages = React.lazy(
    () => import('app/modules/BasicInfo/UnplannedStoppages/UnplannedStoppages'),
);

const EmployeeForm = React.lazy(
    () => import('app/modules/BasicInfo/Employees/EmployeeForm'),
);
const AddEditFactory = React.lazy(
    () =>
        import(
            'app/modules/CompanyManagement/Factories/AddEditFactory/AddEditFactory'
        ),
);
const FingerprintDevices = React.lazy(
    () =>
        import(
            'app/modules/CompanyManagement/FingerprintDevices/FingerprintDevices'
        ),
);

const Defects = React.lazy(() => import('../Quality/Defects/Defects'));
const InlineQualityInspectionReport = React.lazy(
    () =>
        import(
            'app/modules/Quality/InlineQualityInspectionReport/InlineQualityInspectionReport'
        ),
);

const InlineQualityDefectsAnalysis = React.lazy(
    () =>
        import(
            'app/modules/Quality/InlineQualityDefectsAnalysis/InlineQualityDefectsAnalysis'
        ),
);

const InlineQualitySupervisorPerformanceReport = React.lazy(
    () =>
        import(
            'app/modules/Quality/inlineQualitySupervisorPerformanceReport/inlineQualitySupervisorPerformanceReport'
        ),
);

const RepairsReport = React.lazy(
    () => import('app/modules/Quality/RepairsReport/RepairsReport'),
);

const QualityStationInspectionReport = React.lazy(
    () =>
        import(
            'app/modules/Quality/QualityStationInspectionReport/QualityStationInspectionReport'
        ),
);

const QualityStationDefectsAnalysis = React.lazy(
    () =>
        import(
            'app/modules/Quality/QualityStationDefectsAnalysis/QualityStationDefectsAnalysis'
        ),
);

const QualityStationSupervisorReport = React.lazy(
    () =>
        import(
            '../Quality/QualityStationSupervisor/QualityStationSupervisorReport'
        ),
);

const AggregatedQualityReport = React.lazy(
    () => import('../Quality/AggregatedQualityReport/AggregatedQualityReport'),
);

const ProductionOrderInspections = React.lazy(
    () => import('../Quality/AQL/inspections/index'),
);

const AuditCreation = React.lazy(
    () => import('../Quality/AQL/AuditCreation/index'),
);

const ThroughputReport = React.lazy(
    () =>
        import(
            'app/modules/AnalyticsAndReports/ThroughputReport/ThroughputReport'
        ),
);

const WorkingHours = React.lazy(
    () => import('app/modules/CompanyManagement/WorkingHours'),
);

const QualityGrades = React.lazy(
    () => import('../Quality/QualityGrades/QualityGrades'),
);

const Style = React.lazy(() => import('app/modules/Production/Style/Style'));

const ProductionOrderBatches = React.lazy(
    () => import('app/modules/Production/Order/Batches'),
);

const ProductionOrderPreview = React.lazy(
    () => import('app/modules/Production/Order/Preview/Preview'),
);

const ProductionOrderReport = React.lazy(
    () => import('app/modules/Production/Order/Report/Report'),
);

const BatchesPrintView = React.lazy(
    () =>
        import(
            'app/modules/Production/Order/Batches/BatchesCrud/Printing/BatchesTable'
        ),
);

const BatchGroupsPrintView = React.lazy(
    () =>
        import(
            'app/modules/Production/Order/Batches/BatchGroups/PrintGroups/BatchGroupsTable'
        ),
);

const BatchesSelector = React.lazy(
    () => import('app/modules/Quality/AQL/BatchesSelector/BatchesSelector'),
);

const AQLInspectionsReport = React.lazy(
    () => import('app/modules/Quality/AQL/InspectionsReport/InspectionsReport'),
);

const AuditView = React.lazy(() => import('../Quality/AQL/AuditView/index'));

const DeviceFingerprints = React.lazy(
    () =>
        import(
            'app/modules/CompanyManagement/FingerprintDevices/DeviceFingerprints'
        ),
);

const SizeGroups = React.lazy(
    () => import('app/modules/Production/Sizes/SizeGroups'),
);

const Colors = React.lazy(() => import('app/modules/Production/Colors/Colors'));

const DeletedColors = React.lazy(
    () => import('app/modules/Production/Colors/DeletedColors'),
);

const Roll = React.lazy(
    () => import('app/modules/Cutting/Rolls/PaginatedRolls'),
);

const Markers = React.lazy(() => import('app/modules/Cutting/Markers/Markers'));

const FabricTypes = React.lazy(
    () => import('app/modules/Cutting/FabricTypes/FabricTypes'),
);

const DeletedFabricTypes = React.lazy(
    () => import('app/modules/Cutting/FabricTypes/DeletedFabricTypes'),
);

const MarkersImagePrint = React.lazy(
    () => import('app/modules/Cutting/Markers/components/ImagePrint'),
);

const Machines = React.lazy(
    () => import('app/modules/MachinesAndDevices/Machines/Machines'),
);

const Clients = React.lazy(
    () => import('app/modules/BasicInfo/Clients/Clients'),
);

const MachineTypes = React.lazy(
    () => import('app/modules/MachinesAndDevices/MachineTypes/MachineTypes'),
);

const ProcessThroughputReport = React.lazy(
    () =>
        import(
            'app/modules/AnalyticsAndReports/ProcessThroughputReport/ProcessThroughputReport'
        ),
);

const StandardProcessThroughputReport = React.lazy(
    () =>
        import(
            'app/modules/AnalyticsAndReports/StandardProcessThroughputReport/StandardProcessThroughputReport'
        ),
);

const WipReport = React.lazy(
    () => import('app/modules/AnalyticsAndReports/WipReport/WipReport'),
);

const KanbanDashboardReport = React.lazy(
    () =>
        import(
            'app/modules/AnalyticsAndReports/KanbanDashboardReport/KanbanDashboardContext'
        ),
);

const WastedDurationReport = React.lazy(
    () =>
        import(
            'app/modules/AnalyticsAndReports/WastedDurationReport/WastedDurationReport'
        ),
);

const EmployeesStatus = React.lazy(
    () =>
        import(
            'app/modules/AnalyticsAndReports/EmployeesStatus/EmployeesStatus'
        ),
);

const Devices = React.lazy(
    () => import('app/modules/MachinesAndDevices/Devices/Devices'),
);

const DeviceGroups = React.lazy(
    () => import('app/modules/MachinesAndDevices/DeviceGroups/DeviceGroups'),
);

const LiveFeed = React.lazy(() => import('app/modules/LiveFeed/LiveFeed'));

const ActionItems = React.lazy(
    () => import('app/modules/ActionItems/ActionItems'),
);

const Users = React.lazy(
    () => import('app/modules/CompanyManagement/Users/Users'),
);

const CustomReportsLinks = React.lazy(
    () => import('app/modules/CompanyManagement/CustomReportsLinks'),
);

const routes = [
    {
        path: '/',
        component: <LandPage />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: true,
        auth: true,
    },
    {
        path: '/admin/:page',
        component: <Admin />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: false,
        auth: 'admin',
    },
    {
        path: '/:factoryId/employee',
        component: <EmployeeForm />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employees_add'],
    },
    {
        path: '/:factoryId/shifts',
        component: <WorkingHours />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: [
            'working_hours_access',
            'working_hours_view',
            'working_hours_list',
        ],
    },
    {
        path: '/:factoryId/employee/:employeeId',
        component: <EmployeeForm />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employees_edit'],
    },
    {
        path: '/:factoryId/employees',
        component: <Employees />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employees_list'],
    },
    {
        path: '/:factoryId/unplanned_stoppages',
        component: <UnplannedStoppages />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['stoppages_access'],
    },
    {
        path: '/:factoryId/employee_score_calculator',
        component: <EmployeeScoreCalculator />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: [
            'employee_score_schema_access',
            'employee_score_schema_add',
            'employee_score_schema_edit',
            'employee_score_schema_delete',
            'employee_score_schema_view',
        ],
    },
    {
        path: '/:factoryId/factoryScorecardReport',
        component: <FactoryScorecardReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['factory_scorecard_access'],
    },
    {
        path: '/:factoryId/EmployeeScoreCard',
        component: <EmployeeScoreCard />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employee_scorecard_access'],
    },
    {
        path: '/:factoryId/dashboard',
        component: <Dashboard />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['dashboard_access'],
    },
    {
        path: '/:factoryId/hourlyReport',
        component: <HourlyReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['hourly_reports_access'],
    },
    {
        path: '/styleCategory',
        component: <StyleCategoryForm />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['style_categories_processTypes_standardProcesses_edit'],
    },
    {
        path: '/styleCategory/:styleCategoryId/:processToEdit?',
        component: <StyleCategory />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['style_categories_processTypes_standardProcesses_edit'],
    },
    {
        path: '/:factoryId/styleCategories',
        component: <StyleCategories />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['style_categories_processTypes_standardProcesses_list'],
    },
    {
        path: '/:factoryId/sizeGroups',
        component: <SizeGroups />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['sizes_access'],
    },
    {
        path: '/:factoryId/colors',
        component: <Colors />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['colors_access'],
    },
    {
        path: '/:factoryId/colors/deleted',
        component: <DeletedColors />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['colors_access'],
    },
    {
        path: '/:factoryId/styleCategories/:styleCategoryId/combinations',
        component: <ProcessCombinations />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['style_categories_processTypes_standardProcesses_edit'],
    },
    {
        path: '/:factoryId/styleCategories/:styleCategoryId/combination',
        component: <ProcessCombination />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['style_categories_processTypes_standardProcesses_edit'],
    },
    {
        path: '/:factoryId/styleCategories/:styleCategoryId/combinations/:processCombinationId',
        component: <ProcessCombination />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['style_categories_processTypes_standardProcesses_edit'],
    },
    {
        path: '/:factoryId/sectionTags',
        component: <SectionTags />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['production_lines_access'],
    },
    {
        path: '/:factoryId/productionLines',
        component: <ProductionLines />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['production_lines_access'],
    },
    {
        path: '/:factoryId/styles',
        component: <Styles />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['styles_list'],
    },
    {
        path: '/:factoryId/style/:styleId/printView',
        component: <StylePrint />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['styles_view'],
    },
    {
        path: '/:factoryId/style/:styleId',
        component: <Style />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['styles_edit'],
    },
    {
        path: '/:factoryId/style/:styleId/processesMap',
        component: <ProcessesMap />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['processes_map_access'],
    },
    {
        path: '/:factoryId/style',
        component: <Style />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['styles_add'],
    },
    {
        path: '/:factoryId/roll',
        component: <Roll />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['roll_list'],
        canActivate: cuttingEnabled,
    },
    {
        path: '/:factoryId/productionOrders',
        component: <ProductionOrders />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['orders_list'],
    },
    {
        path: '/:factoryId/productionOrders/deleted',
        component: <DeletedProductionOrders />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['orders_list'],
    },
    {
        path: '/:factoryId/productionOrders/add',
        component: <AddEditProductionOrder />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['orders_add'],
    },
    {
        path: '/:factoryId/productionOrders/:productionOrderId',
        component: <AddEditProductionOrder />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['orders_edit'],
    },
    {
        path: '/:factoryId/cuttingOrders',
        component: <CuttingOrders />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['cutting_orders_list'],
    },
    {
        path: '/:factoryId/productionOrders/:productionOrderId/cuttingOrders',
        component: <CuttingOrders />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['cutting_orders_list'],
    },
    {
        path: '/:factoryId/cuttingOrder',
        component: <AddEditCuttingOrder />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['cutting_orders_add'],
    },
    {
        path: '/:factoryId/cuttingOrder/:cuttingOrderId/details',
        component: <CuttingOrderDetails />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['cutting_orders_view'],
    },
    {
        path: '/:factoryId/cuttingOrder/:cuttingOrderId',
        component: <AddEditCuttingOrder />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['cutting_orders_edit'],
    },
    {
        path: '/:factoryId/productionPlan',
        component: <ProductionPlan />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['cutting_orders_edit'],
    },
    {
        path: '/:factoryId/productionOrder/:orderId/batches',
        component: <ProductionOrderBatches />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['batches_list'],
    },
    {
        path: '/:factoryId/productionOrder/:orderId/preview',
        component: <ProductionOrderPreview />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['orders_view'],
    },
    {
        path: '/:factoryId/productionOrder/:orderId/report',
        component: <ProductionOrderReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['po_report_access'],
    },
    {
        path: '/:factoryId/productionOrder/:orderId/markers',
        component: <Markers />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['markers_list'],
        canActivate: cuttingEnabled,
    },
    {
        path: '/printImage/',
        component: <MarkersImagePrint />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: true,
        auth: true,
        permissions: ['markers_list'],
        canActivate: cuttingEnabled,
    },
    {
        path: '/:factoryId/fabricTypes',
        component: <FabricTypes />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['fabricTypes_list'],
    },
    {
        path: '/:factoryId/fabricTypes/deleted',
        component: <DeletedFabricTypes />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['fabricTypes_list'],
    },
    {
        path: '/:factoryId/productionOrder/:orderId/batches/printView',
        component: <BatchesPrintView />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: true,
        auth: true,
        permissions: ['batches_list'],
    },
    {
        path: '/:factoryId/productionOrder/:orderId/cuttingOrder/:cuttingOrderId/batches/printView',
        component: <BatchesPrintView />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: true,
        auth: true,
        permissions: ['batches_list'],
    },
    {
        path: '/:factoryId/productionOrder/:orderId/batchGroups/printView',
        component: <BatchGroupsPrintView />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: true,
        auth: true,
        permissions: ['batch_grouping_access'],
    },
    {
        path: '/:factoryId/batch_history/:orderId',
        component: <BatchHistory />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['batches_history_access'],
    },
    {
        path: '/:factoryId/batch_historyV2/:orderId',
        component: <BatchHistoryV2 />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['batches_history_access'],
    },
    {
        path: '/:factoryId/employees_efficiency',
        component: <EmployeesEfficiency />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employee_effeciency_access'],
    },
    {
        path: '/:factoryId/employee_history/:employeeId',
        component: <EmployeeHistory />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employee_history_access'],
    },
    {
        path: '/:factoryId/line_feeding',
        component: <LineFeedingReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['line_feeding_access'],
    },
    {
        path: '/:factoryId/linesEfficiency',
        component: <LinesEfficiencyReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['lines_efficiency_access'],
    },
    {
        path: '/:factoryId/linesPerformance',
        component: <LinesPerformanceReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['lines_performance_report_access'],
    },
    {
        path: '/:factoryId/pocosting/:productionOrderId',
        component: <ProductionOrderCostingReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['pocosting_report_access'],
    },
    {
        path: '/:factoryId/productionOrder/:productionOrderId/planning',
        component: <ProductionOrderPlanning />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['production_order_planning_access'],
    },
    {
        path: '/:factoryId/maintenance-report',
        component: <MaintenanceReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['maintenance_report_list'],
    },
    {
        path: '/:factoryId/maintenanceSupervisorReport',
        component: <MaintenanceSupervisorReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['maintenance_report_list'],
    },
    {
        path: '/:factoryId/throughputReport',
        component: <ThroughputReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['throughput_report_access'],
    },
    {
        path: '/:factoryId/processThroughputReport/:productionOrderId/:processId',
        component: <ProcessThroughputReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['process_throughput_report_access'],
    },
    {
        path: '/:factoryId/styleProcessThroughputReport/:styleId/:processId',
        component: <ProcessThroughputReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['process_throughput_report_access'],
    },
    {
        path: '/:factoryId/StandardProcessThroughputReport/:standardProcessId',
        component: <StandardProcessThroughputReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['process_throughput_report_access'],
    },
    {
        path: '/:factoryId/wipReport/',
        component: <WipReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['wip_report_access'],
    },
    {
        path: '/:factoryId/kanbanDashboardReport/',
        component: <KanbanDashboardReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['kanban_dashboard_report_access'],
    },
    {
        path: '/:factoryId/wastedDurationReport/',
        component: <WastedDurationReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['wasted_duration_report_access'],
    },
    {
        path: '/:factoryId/displays',
        component: <Displays />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['display_access'],
    },
    {
        path: '/:factoryId/StyleCategoryAnalysis/:styleCategoryId',
        component: <StyleCategoryAnalysis />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
    },
    {
        path: '/:factoryId/maintenanceReasons',
        component: <MaintenanceDowntimeReasons />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['maintenance_reasons_access'],
    },
    {
        path: '/:factoryId/machineDefectsAnalysis',
        component: <InlineMachineDefects />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['maintenance_report_list'],
    },
    {
        path: '/:factoryId/stations',
        component: <Stations />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['control_station_interface_access'],
    },
    {
        path: '/:factoryId/stations/:stationId',
        component: <Station />,
        layout: {
            header: false,
            footer: false,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['control_station_interface_access'],
    },
    {
        path: '/:factoryId/stations-manager',
        component: <StationsManager />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['control_station_access'],
    },
    {
        path: '/:factoryId/controlStationThroughput/:controlStationId',
        component: <ControlStationThroughput />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['cs_throughput_report_access'],
    },
    {
        path: '/:factoryId/manual_working_hours',
        component: <ManualWorkingHours />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employees_working_hours_access_old'],
    },
    {
        path: '/:factoryId/manual_working_hours_v2',
        component: <NewManualWorkingHours />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employees_working_hours_access'],
    },
    {
        path: '/:factoryId/overheadCosts',
        component: <Overhead />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['overhead_access'],
    },
    {
        path: '/:factoryId/aggregatedCostingReport',
        component: <AggregatedCostingReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['aggregated_costing_report_access'],
    },
    {
        path: '/:factoryId/idleTimeReasons',
        component: <IdleTimeReasons />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['idle_time_reason_access'],
        canActivate: idleTimeEnabled,
    },
    {
        path: '/:factoryId/s',
        component: <Supervisor />,
        layout: {
            header: false,
            footer: false,
            side: true,
        },
        exact: false,
        auth: true,
        permissions: [
            'control_station_interface_access',
            'batches_history_access',
            'batchGroups_history_access',
            'device_processes_supervise_access',
            'inline_quality_inspection_add',
        ],
    },
    {
        path: '/:factoryId/idleTimes',
        component: <IdleTimes />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['idle_time_list'],
        canActivate: idleTimeEnabled,
    },
    {
        path: '/:factoryId/idleTimeEntry/:idleTimeId',
        component: <IdleTimeEntry />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['idle_time_list'],
    },
    {
        path: '/:factoryId/IdleTimeReasonsAnalysis',
        component: <IdleTimeReasonsAnalysis />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['idle_time_reason_access'],
        canActivate: idleTimeEnabled,
    },
    {
        path: '/:factoryId/app',
        component: <Supervisor />,
        layout: {
            header: false,
            footer: false,
            side: true,
        },
        exact: false,
        auth: true,
        permissions: [
            'control_station_interface_access',
            'batches_history_access',
            'batchGroups_history_access',
            'device_processes_supervise_access',
            'inline_quality_inspection_add',
        ],
    },
    {
        path: '/:factoryId/maintenance-requests',
        component: <MaintenanceRequests />,
        layout: {
            header: false,
            footer: true,
            side: true,
        },
        permissions: ['maintenance_view', 'maintenance_access'],
        exact: false,
        auth: true,
    },
    {
        path: '/factories',
        component: <Factories />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['factories_list'],
    },
    {
        path: '/factories/add',
        component: <AddEditFactory type='add' />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['factories_edit', 'factories_view'],
    },
    {
        path: '/:factoryId/fingerprintDevices',
        component: <FingerprintDevices />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['factories_access'],
    },
    {
        path: '/deviceFingerprints/:deviceId/:deviceName/:lastSync',
        component: <DeviceFingerprints />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['factories_access'],
    },
    {
        path: '/:factoryId/inlineQualityInspectionReport',
        component: <InlineQualityInspectionReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['inline_quality_inspection_list'],
    },
    {
        path: '/:factoryId/inlineQualityDefectsAnalysis',
        component: <InlineQualityDefectsAnalysis />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['inline_quality_inspection_list'],
    },
    {
        path: '/:factoryId/inlineQualitySupervisorPerformanceReport',
        component: <InlineQualitySupervisorPerformanceReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['inline_quality_inspection_list'],
    },
    {
        path: '/:factoryId/qualityStationInspectionReport',
        component: <QualityStationInspectionReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['quality_station_inspection_list'],
    },
    {
        path: '/:factoryId/qualityStationDefectsAnalysis',
        component: <QualityStationDefectsAnalysis />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['quality_station_inspection_list'],
    },
    {
        path: '/:factoryId/QualityStationSupervisorReport',
        component: <QualityStationSupervisorReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['quality_station_inspection_list'],
    },
    {
        path: '/:factoryId/QualityStationThroughput',
        component: <QualityStationThroughput />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['quality_station_inspection_throughput_access'],
    },
    {
        path: '/:factoryId/AggregatedQualityReport',
        component: <AggregatedQualityReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['aggregated_quality_report_list'],
    },
    {
        path: '/:factoryId/productionOrderInspections',
        component: <ProductionOrderInspections />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['aql_audit_list'],
        canActivate: AQLEnabled,
    },
    {
        path: '/:factoryId/productionOrderInspections/:productionOrder/inspection',
        component: <AuditCreation />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['aql_audit_add'],
        canActivate: AQLEnabled,
    },
    {
        path: '/factories/edit/:editFactoryid',
        component: <AddEditFactory type='edit' />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['factories_edit', 'factories_view'],
    },
    {
        path: '/:factoryId/defects',
        component: <Defects />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['defect_access'],
    },
    {
        path: '/:factoryId/defectsGroup',
        component: <DefectsGroup />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['defect_access'],
    },
    {
        path: '/:factoryId/inlineQualityInspection/:id',
        component: <SingleInspectionDetail />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['inline_quality_inspection_view'],
    },
    {
        path: '/:factoryId/qualityGrades',
        component: <QualityGrades />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['quality_grades_access'],
    },
    {
        path: '/:factoryId/translation',
        component: <TransLations />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['translations_access'],
    },
    {
        path: '/:factoryId/roles',
        component: <CompanyRoles />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['roles_access'],
    },
    {
        path: '/roles/add',
        component: <AddEditRole />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['roles_access'],
    },
    {
        path: '/roles/:roleId',
        component: <AddEditRole />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['roles_access'],
    },
    {
        path: '/:factoryId/RepairsReport',
        component: <RepairsReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['quality_station_inspection_repairs_list'],
    },
    {
        path: '/:factoryId/productionOrderInspections/:productionOrderId',
        component: <BatchesSelector />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['aql_audit_add'],
        canActivate: AQLEnabled,
    },
    {
        path: '/:factoryId/aql/InspectionsReport',
        component: <AQLInspectionsReport />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['aql_audit_list'],
        canActivate: AQLEnabled,
    },
    {
        path: '/:factoryId/aql/InspectionsReport/:auditId',
        component: <AuditView />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['aql_audit_view'],
        canActivate: AQLEnabled,
    },
    {
        path: '/:factoryId/machines',
        component: <Machines />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['machine_access'],
    },
    {
        path: '/settings/notifications',
        component: <NotificationsSettings />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
    },
    {
        path: '/settings/changePassword',
        component: <ChangePassword />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
    },
    {
        path: '/:factory/logs',
        component: <ActionsLogs />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['user_logs_list'],
    },
    {
        path: '/:factory/customReportsLinks',
        component: <CustomReportsLinks />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['custom_reports_links_access'],
    },
    {
        path: '/:factory/clients',
        component: <Clients />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['clients_list'],
    },
    {
        path: '/:factory/users',
        component: <Users />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['users_access'],
    },
    {
        path: '/:factory/machineTypes',
        component: <MachineTypes />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['machine_types_access'],
    },
    {
        path: '/:factory/employeesStatus',
        component: <EmployeesStatus />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['employees_status_access'],
    },
    {
        path: '/:factory/devices',
        component: <Devices />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['devices_access'],
    },
    {
        path: '/:factory/deviceGroups',
        component: <DeviceGroups />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['device_groups_access'],
    },
    {
        path: '/:factory/liveFeed',
        component: <LiveFeed />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['live_feed_access'],
    },
    {
        path: '/:factory/actionItems',
        component: <ActionItems />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
        permissions: ['action_items_view'],
    },
    {
        path: '/signin',
        component: <Singin />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: true,
        auth: false,
    },
    {
        path: '/reload',
        component: <ReloadPage />,
        layout: {
            header: false,
            footer: false,
            side: false,
        },
        exact: true,
        auth: true,
    },
    {
        path: '/404',
        component: <ErrorPage />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
    },
    {
        path: '/403',
        component: <AccessDenied />,
        layout: {
            header: true,
            footer: true,
            side: true,
        },
        exact: true,
        auth: true,
    },
];

export default routes;
